<template>
    <div class="button-dispatch">
        <button class="button is-warning is-fullwidth"
                v-on:click="dispatchOrder">
            <strong>Dispatch</strong>
        </button>
        <b-modal v-bind:active.sync="modalOpen">
            {{ order }}
        </b-modal>

        <!-- <modal-dispatch-order></modal-dispatch-order> -->
    </div>
</template>

<script>
import ModalDispatchOrder from "../modals/ModalDispatchOrder";
export default {
    name: "ButtonDispatch",
    components: {
        ModalDispatchOrder
    },
    props: {
        orderId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            modalOpen: false
        }
    },
    computed: {
        order() {
            let orders = this.$store.state.shop.orders
            if (orders && Array.isArray(orders)) {
                let target = orders.find(order => order._id === this.orderId)
                if (target) {
                    return target
                }
            }
            return null
        }
    },
    methods: {
        dispatchOrder() {
            this.modalOpen = true
        }
    }
}
</script>

<style lang="scss" scoped>
.button-dispatch {
    width: 100%;
}
</style>
