<template>
    <article class="media mb-0">
        <figure class="media-left mb-1">
            <p class="image is-96x96">
                <img v-bind:alt="item.title['en']"
                     v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/product/image/${ item.images[0] }`">
            </p>
        </figure>
        <div class="media-content">
            <div class="content">
                <h1 class="title bold is-5 is-marginless"
                    v-html="item.title['en']">
                </h1>
                <p class="mb-2"
                   v-html="item.subtitle['en']">
                </p>
                <p class="mb-0">
                    <strong>Unit price: </strong>HK${{ item.prices[0].hkd }}
                </p>
                <p>
                    <strong>Quantity: </strong> {{ item.quantity }}
                </p>
            </div>
        </div>
        <div class="media-right">
            <div class="content">
                <h6 class="is-6">
                    <small>Total: </small>
                    {{ item.quantity * item.prices[0].hkd }}
                </h6>
            </div>
        </div>
    </article>
</template>

<script>
export default {
    name: "OrderItem",
    props: {
        item: {
            type: Object,
        },
    },
    data() {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
