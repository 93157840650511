<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Payment
            </p>
        </header>
        <div class="card-content">
            <div class="content">
                <h6 class="title is-6 is-marginless">Order ID</h6>
                <p>{{ orderId }}</p>
                <template v-if="payment">
                    <h6 class="title is-6 is-marginless">Method</h6>
                    <p>
                        {{ paymentMethodName }}
                    </p>
                </template>
                <template v-if="orderStatus !== 'pending'">
                    <h6 class="title is-6 is-marginless"
                        v-if="['cards', 'payment_request_api'].includes(payment.paymentMethod)">
                        Details
                    </h6>
                    <p v-if="['cards', 'payment_request_api'].includes(payment.paymentMethod)">
                        {{ paymentMethodBrand }} / {{ paymentMethodLast4 }}
                    </p>
                    <h6 class="title is-6" style="margin-bottom: 0.5rem"
                        v-if="payment.transaction">
                        Handler Response
                    </h6>
                    <pre v-if="transaction">
                        <code>
                            {{ transaction }}
                        </code>
                    </pre>
                </template>
                <h5 class="is-5" v-else>
                    <span class="tag is-danger is-medium">
                        Pending Payment
                    </span>
                </h5>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrderPayment",
    props: {
        orderId: {
            type: String,
            required: true,
        },
        orderStatus: {
            type: String,
            required: true,
        },
        payment: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        transaction () {
            return this.payment?.transaction ?? "";
        },
        charge () {
            return this.transaction?.charges?.data?.[0];
        },
        paymentMethodDetails () {
            return this.charge?.payment_method_details;
        },
        paymentMethodBrand () {
            let brand = this.paymentMethodDetails?.card?.brand ?? "N.A.";
            brand = brand.replace("_", " ");
            return `${ brand[0].toUpperCase() }${ brand.slice(1) }`;
        },
        paymentMethodLast4 () {
            return this.paymentMethodDetails?.card?.last4 ?? "N.A.";
        },
        paymentWalletType () {
            let walletType = Object.keys(this.paymentMethodDetails?.card?.wallet ?? {})?.[0] ?? "Payment Request API";
            walletType = walletType.replace("_", " ");
            return `${ walletType[0].toUpperCase() }${ walletType.slice(1) }`;
        },
        paymentMethodName () {
            if (this.payment.paymentMethod === "cards") {
                return `Cards - ${ this.paymentMethodBrand }`;
            }
            if (this.payment.paymentMethod === "alipay") {
                return "Alipay";
            }
            if (this.payment.paymentMethod === "wechat_pay") {
                return "WeChat Pay";
            }
            if (this.payment.paymentMethod === "paypal") {
                return "PayPal";
            }
            if (this.payment.paymentMethod === "payment_request_api") {
                return `${ this.paymentWalletType } - ${ this.paymentMethodBrand }`;
            }
            return "Unknown";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
pre {
    max-height: 150px;
    max-width: 26vw;
    overflow: auto;
    white-space: pre-wrap;
}
</style>
