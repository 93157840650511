<template>
    <div class="card">
        <div class="card-content">
            <div class="content">
                <h1 class="title is-5 mb-1">
                    {{ coupon.code }}
                </h1>
                <p class="">
                    {{ coupon.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrderCoupon",
    props: {
        coupon: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.title {
    font-weight: 700;
}
</style>
