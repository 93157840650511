<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Dispatch Order</p>
        </header>
        <section class="modal-card-body">
            {{ order }}
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button"
                    v-on:click="$parent.close()">
                Cancel
            </button>
            <button class="button is-primary"
                    v-if="mode === 'add'"
                    v-bind:disabled="!allowSave"
                    v-bind:class="{ 'is-loading': loading }"
                    v-on:click="save">
                Save
            </button>
            <button class="button is-primary"
                    v-if="mode === 'edit'"
                    v-bind:disabled="!allowSave"
                    v-bind:class="{ 'is-loading': loading }"
                    v-on:click="update">
                Update
            </button>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'ModalDispatchOrder',
        props: {
            orderId: {
                type: String,
                required: true
            }
        },
        data() {
            return {}
        },
        computed: {
            order() {
                if (this.orderId) {
                    let order = this.$store.state.shop.orders.find(
                        order => order._id === this.orderId
                    )
                    if (order) {
                        return order
                    }
                }
                return null
            }
        },
        mounted() {

        }
    }
</script>

<style lang="scss" scoped>

</style>
