<template>
    <section class="order-details">
        <b-message has-icon type="is-dark"
                   v-if="status === 'cancelled'">
            <span>This order has been cancelled. </span>
        </b-message>

        <b-message has-icon
                   v-if="status === 'pending'">
            <div class="columns">
                <div class="column is-three-quarters is-flex align-items-center">
                    <span>This order is pending payment. </span>
                </div>
                <div class="column is-flex is-align-items-center is-justify-content-center">
                    <button class="button"
                            style="font-weight: 500; ">
                        Close Order
                    </button>
                </div>
            </div>
        </b-message>

        <b-message has-icon type="is-warning"
                   v-if="status === 'accepted'">
            <div class="columns">
                <div class="column is-three-quarters is-flex align-items-center">
                    <span>This order has been accepted and paid for. Please arrange dispatch. </span>
                </div>
                <div class="column is-flex is-align-items-center is-justify-content-end">
                    <button-dispatch style="font-weight: 500; "
                                     v-bind:order-id="orderId">
                    </button-dispatch>
                </div>
            </div>
        </b-message>

        <b-message has-icon type="is-success"
                   v-if="['dispatched', 'delivered'].includes(status)">
            <div class="columns">
                <div class="column is-three-quarters is-flex align-center">
                    <span>This order has been dispatched. </span>
                </div>
                <div class="column is-flex align-center">
                    <button class="button is-success"
                            style="font-weight: 500; ">
                        View Tracking
                    </button>
                </div>
            </div>
        </b-message>

        <div class="columns">
            <div class="column">
                <div class="card is-flex is-flex-direction-column">
                    <header class="card-header">
                        <p class="card-header-title">
                            Contact and Address
                        </p>
                    </header>
                    <div class="card-content is-flex-grow-1">
                        <div class="content">
                            <h6 class="title is-6 is-marginless">Email</h6>
                            <p>{{ order.delivery.email }}</p>
                            <h6 class="title is-6 is-marginless">Phone</h6>
                            <p>{{ order.delivery.phone }}</p>
                            <h6 class="title is-6 is-marginless">
                                {{ `${ order.delivery.address.firstName } ${ order.delivery.address.lastName }` }}
                            </h6>
                            <p class="is-marginless">{{ order.delivery.address.addressLine1 }}</p>
                            <p class="is-marginless">{{ order.delivery.address.addressLine2 }}</p>
                            <p class="is-marginless">
                                <span v-if="order.delivery.postcode">{{ order.delivery.address.postcode }}, </span>
                                {{ typeof order.delivery.address.country === "string" ? order.delivery.address.country : order.delivery.address.country.name["zh-hk"] }}
                            </p>
                        </div>
                    </div>
                    <footer class="card-footer">
                        <a href="#" class="card-footer-item">
                            <font-awesome-icon icon="edit" style="margin-right: 10px"></font-awesome-icon>
                            <span>Edit</span>
                        </a>
                    </footer>
                </div>
            </div>
            <div class="column">
                <order-payment v-bind:order-id="orderId"
                               v-bind:order-status="status"
                               v-bind:payment="order.payment">
                </order-payment>
            </div>
            <div class="column is-3">
                <div class="card">
                    <div class="card-content">
                        <div class="content">
                            <div class="columns is-marginless">
                                <div class="column">
                                    <button-invoice v-bind:order-id="orderId"></button-invoice>
                                </div>
                            </div>

                            <div class="columns is-marginless">
                                <div class="column"
                                     v-if="status === 'accepted'">
                                    <button-receipt v-bind:order-id="orderId"></button-receipt>
                                </div>
                            </div>
                        </div>

                        <div class="content">
                            <div class="columns is-marginless">
                                <div class="column"
                                     v-if="status === 'accepted'">
                                    <button-dispatch v-bind:order-id="orderId"></button-dispatch>
                                </div>
                            </div>

                            <div class="columns is-marginless">
                                <div class="column"
                                     v-if="status === 'accepted'">
                                    <button-refund v-bind:order-id="orderId"></button-refund>
                                </div>
                            </div>

                            <div class="columns is-marginless">
                                <div class="column">
                                    <button-close v-bind:order-id="orderId"></button-close>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <order-items v-bind:items="order.items"></order-items>
            </div>
        </div>
        <div class="columns">
            <div class="column is-6">
                <order-coupons v-if="order.coupons && order.coupons.length > 0"
                               v-bind:coupons="order.coupons">
                </order-coupons>
            </div>
            <div class="column is-6">

            </div>
        </div>
    </section>
</template>

<script>
import OrderPayment from "@/components/Shop/Orders/OrderTable/OrderDetails/OrderPayment.vue";
import OrderCoupons from "@/components/Shop/Orders/OrderTable/OrderDetails/OrderCoupons.vue";
import OrderItems from "@/components/Shop/Orders/OrderTable/OrderDetails/OrderItems.vue";
import ButtonRefund from "@/components/Shop/ButtonRefund.vue";
import ButtonClose from "@/components/Shop/ButtonClose.vue";
import ButtonDispatch from "@/components/Shop/ButtonDispatch.vue";
import ButtonReceipt from "@/components/Shop/ButtonReceipt.vue";
import ButtonInvoice from "@/components/Shop/ButtonInvoice.vue";
import ModalDispatchOrder from "@/components/modals/ModalDispatchOrder.vue";
export default {
    name: "OrderDetails",
    components: {
        OrderPayment, OrderCoupons, OrderItems,
        ButtonRefund, ButtonClose, ButtonDispatch, ButtonReceipt, ButtonInvoice,
        ModalDispatchOrder,
    },
    props: {
        order: {
            type: Object,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        orderId () {
            return this.order._id;
        },
        status () {
            return this.order.status ?? "pending";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
}
</style>
