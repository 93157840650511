<template>
    <b-table detailed
             detail-key="_id"
             v-bind:opened-detailed="openedRow"
             v-bind:data="orders"
             v-bind:row-class="(row, index) => rowStatusMap[row.status]">
        <template>
            <b-table-column v-slot="{ row }"
                            field="createdAt"
                            label="Created at"
                            sortable>
                {{ row.createdAt | dateFnsFormatter("d/MMM/yyyy hh:mm a") }}
            </b-table-column>

            <b-table-column v-slot="{ row }"
                            field="owner"
                            label="Customer"
                            sortable>
                <router-link
                    v-bind:to="{ name: '', params: { id: verifiedCustomers.find(({ _id }) => _id === row.owner)._id } }">
                    {{ verifiedCustomers.find(({ _id }) => _id === row.owner).email }}
                </router-link>
            </b-table-column>

            <b-table-column v-slot="{ row }"
                            field="items.length"
                            label="Item count"
                            numeric
                            centered
                            sortable>
                {{ row.items.reduce((acc, item) => acc += item.quantity, 0) }}
            </b-table-column>

            <b-table-column v-slot="{ row }"
                            field="delivery.deliveryType"
                            label="Delivery"
                            centered>
                {{ row.delivery.deliveryType | capitalize }}
            </b-table-column>

            <b-table-column v-slot="{ row }"
                            field="items"
                            label="Total (HK$)"
                            numeric
                            sortable
                            centered>
                <strong>
                    {{ row.orderTotal ? row.orderTotal : row.items.reduce((acc, item) => acc += item.quantity * item.prices[0].hkd, 0) | currency("", 2) }}
                </strong>
            </b-table-column>

            <b-table-column v-slot="{ row }"
                            field="payment.paymentMethods"
                            label="Payment Method"
                            centered>
                        <span v-if="row.payment">
                            {{ row.payment.paymentMethod }}
                        </span>
            </b-table-column>

            <b-table-column v-slot="{ row }"
                            field="payment.paymentMethods"
                            label="Status"
                            centered
                            sortable>
                <strong>
                    {{ row.status.toUpperCase() }}
                </strong>
            </b-table-column>
        </template>

        <template v-slot:detail="{ row }">
            <order-details v-bind:order="row"></order-details>
        </template>
    </b-table>
</template>

<script>
import OrderDetails from "@/components/Shop/Orders/OrderTable/OrderDetails.vue";
export default {
    name: "OrderTable",
    components: {
        OrderDetails,
    },
    props: {
        orders: {
            type: Array,
        },
    },
    data () {
        return {
            rowStatusMap: {
                "pending": "is-light",
                "accepted": "is-warning",
                "dispatched": "is-success",
                "delivered": "is-success",
                "cancelled": "is-black"
            },
        };
    },
    computed: {
        verifiedCustomers () {
            return this.$store.state.users.users
                .filter(
                    ({ verified }) => verified === true
                );
        },

        openedRow () {
            const rows = [];
            if (this.orderId) {
                rows.push(this.orderId);
            }
            return rows;
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
