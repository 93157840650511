<template>
    <section class="shop-orders">
        <tool-bar>
            Orders
            <template slot="buttons">
                <div class="level-item">
                    <b-pagination simple
                                  order="is-centered"
                                  v-bind:total="orderTotal"
                                  v-bind:per-page="perPage"
                                  v-model="currentPage">
                    </b-pagination>
                </div>
                <div class="level-item">
                    <button class="button"
                            v-bind:class="{ 'is-loading': isLoading }"
                            v-on:click="refreshOrders">
                        <span class="icon is-small">
                            <font-awesome-icon icon="redo-alt"></font-awesome-icon>
                        </span>
                        <span>Refresh</span>
                    </button>
                </div>
            </template>
        </tool-bar>

        <div class="order-table mb-5">
            <order-table v-bind:orders="paginatedOrders"></order-table>
        </div>
    </section>
</template>

<script>
import Fuse from "fuse.js";

import ToolBar from "../layouts/ToolBar";

import OrderTable from "./Orders/OrderTable";

export default {
    name: "Orders",
    components: {
        OrderTable,
        ToolBar
    },
    props: {
        "orderId": {
            "type": String,
        },
    },
    data () {
        return {
            isLoading: false,
            perPage: 20,
            currentPage: 1,

            searchText: "",
            filteredOrders: this.orders
        }
    },
    computed: {
        orders () {
            return this.$store.getters["shop/orders"]
                .slice(0)
                .reverse();
        },
        orderTotal () {
            return this.orders.length;
        },
        paginatedOrders () {
            return this.orders
                .slice(
                    (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage
                );
        },
        fuseInstance () {
            return this.$store.getters["shop/orderFuse"];
        },
    },
    mounted() {

    },
    methods: {
        async refreshOrders() {
            this.isLoading = true;
            try {
                await this.$store
                    .dispatch(
                        "shop/loadOrders"
                    );
            } catch (e) {
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
        },

        async getFilteredOrders(searchText) {
            this.filteredOrders = this.fuseInstance
                .search(
                    searchText,
                );
        }
    }
}
</script>

<style lang="scss" scoped>
.shop-orders {
    .order-table {
        overflow-y: auto;
    }
}
</style>

<style lang="scss">
.shop-orders {
    tr {
        &.is-light {
            background-color: #f5f5f5;
            color: #363636;
        }

        &.is-dark {
            background-color: #363636;
            color: #f5f5f5;
        }

        &.is-black {
            background-color: #0a0a0a;
            color: #fff;
        }

        &.is-warning {
            background-color: #ffdd57;
            color: rgba(0, 0, 0, .7);
        }

        &.is-success {
            background-color: #23d160;
            color: #fff;
        }

        strong {
            color: inherit;
        }
    }
}
</style>
