<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Items ordered
            </p>
        </header>
        <div class="card-content">
            <div class="content">
                <order-item v-for="item in items"
                            v-bind:key="item._id"
                            v-bind:item="item">
                </order-item>
            </div>
        </div>
    </div>
</template>

<script>
import OrderItem from "./OrderItems/OrderItem";
export default {
    name: "OrderItems",
    components: {
        OrderItem,
    },
    props: {
        items: {
            type: Array,
        },
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
