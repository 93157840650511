<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Coupons applied
            </p>
        </header>
        <div class="card-content">
            <order-coupon v-for="coupon in coupons"
                          v-bind:key="coupon._id"
                          v-bind:coupon="coupon">
            </order-coupon>
        </div>
    </div>
</template>

<script>
import OrderCoupon from "./OrderCoupons/OrderCoupon";
export default {
    name: "OrderCoupons",
    components: {OrderCoupon},
    props: {
        coupons: {
            type: Array,
            default: () => [],
        },
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
